<template>
  <header class="header">
    <div class="container">
      <ul class="header__list">
        <li class="header__item">
            <a class="header__logo" href="https://alrf.ru" target="_blank">
              <img src="@/assets/images/svg/logo.svg" alt="">
            </a>
        </li>
        <!-- <li class="header__item">
          <lang-select
            @translated="translated"
            @langSelect = 'langSelect'
            @langsT = 'langsT'
            :selText = 'langSelectText'
          />
        </li> -->
      </ul>
    </div>
  </header>
</template>
<script>
  import LangSelect from "@/components/LangSelect/LangSelect.vue";

  export default {
    components:
    {
      LangSelect
    },
    props:
    {
      langSelectText:
      {
        type: String,
        default: ''
      },
    },
    setup(props, {emit})
    {
      const langSelect = (val)=>
      {
        emit('langSelect', val)
      }
      const langsT = (val)=>
      {
        emit('langsT', val)
      }
      const translated = (val)=>
      {
        emit('translated', val)
      }
      return{
        translated,
        langSelect,
        langsT
      }
    }
  }
</script>
<style lang="scss">
  @import "TheHeader";
</style>
